#team {

    .team-member {
        border-bottom: 1px solid #b3d2c1;
        margin-bottom: 20px;
        padding-bottom: 5px;
        
        &:last-child {
            border-bottom: 0;
        }
        
        h3, .h3 {
          margin: 0 !important; 
        }
    
        h5, .h5 {
          font-size: 19px;
          font-weight: 400 !important; 
          margin-top: 0 !important; 
          margin-bottom: 25px !important; 
        }
    }

}