.home header { 
    background: #ffffff;
    height: 350px;
    
    @media (min-width: $screen-sm-min) {
        height: 415px;
    }    
    
    #intro {
        margin-top: 60px;
    }

}

#intro {
    font-size: 24px;
    
    @media (max-width: $screen-sm-min - 1) {
        margin-top: 40px;
    }
    
    @media (min-width: $screen-sm-min) {
        font-size: 30px;
        margin-top: 60px;
    }
    
    p {
        @media (max-width: $screen-sm-min - 1) {
            margin: 0 0 10px;
        }
    }
    
    @media (min-width: $screen-md-min) {
        margin-top: 70px;
        font-size: 38px;
        letter-spacing: 5px;
    }
    
    .btn {
        margin: 0 0 20px;
        
        @media (min-width: $screen-md-min) {
            margin: 0 0 40px;
        }
    }
    
    img {
        margin: 0 5px;
    }
}

.line-header {
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 4px;
  padding: 50px 0;
  
    @media (min-width: $screen-sm-min) {
        font-size: 22px;
    }

    @media (min-width: $screen-md-min) {
        font-size: 28px;
    }
}

.line-header span {
  display: inline-block;
  position: relative;  
}

.line-header span:before,
.line-header span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-top: 4px solid #35845a;
  top: 50%;
  margin-top: -4px;
  width: 100%;
}

.line-header span:before {
  right: 100%;
  margin-right: 30px;
}

.line-header span:after {
  left: 100%;
  margin-left: 30px;
}

#home-main-slider {
    @media (min-width: $screen-md-min) {
        padding: 30px 0;
    }
}

#newyork-mapsvg {
    max-width: 700px;
    margin: 0 auto;
}

#usa-map {
    background: #ffffff;
    
    @media (min-width: $screen-md-min) {
        background-size: cover;
    }
}

.popover-arrow {
    @include retina-sprite($popover-arrow-group);
    bottom: 25px;
    right: 35px;
    position: absolute;
}

.popup-info .popover-arrow {
    right: 295px;
}

.client-list {
    padding: 50px 0 70px;
    
    h1, .h1 {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 4px;
        
        @media (min-width: $screen-sm-min) {
            font-size: 22px;
        }
        
        @media (min-width: $screen-md-min) {
            font-size: 28px;
        }
    }
    
    #client-list {
        padding: 75px 0 60px;
    }
}

.explore:before {
    @include retina-sprite($explore-small-group);
    content: " ";
    position: absolute;
    left: 0;
    top: 5px;
}

.download:before {
    @include retina-sprite($download-small-group);
    content: " ";
    position: absolute;
    left: 0;
    top: 5px;
}

#cta {
    background: #ffffff;
    padding: 75px 0 50px;
}

.modal-dialog {
    width: 100%;
    max-width: 1200px;
}

.modal-content {
    border-radius: 0;
    box-shadow: none;
    padding: 50px 75px;
    
    @media (max-width: $screen-sm-min - 1) {
        padding: 20px;
    }
    
    h1, .h1 {
        color: #1a2d2b;
        font-weight: 600;
        font-size: 18px;
        
        @media (min-width: $screen-md-min) {
            font-size: 24px;
        }
        
        @media (min-width: $screen-md-min) {
            margin-top: 0px;
            font-size: 32px;
            letter-spacing: 3px;
        }
    }
    
    .nolabel label,
    label {
        display: none !important;
    }
    
    .haslabel .ginput_container {
        width: 100% !important;
        //margin: 20px;
        margin-left: 0 !important;
    }
        
    .gform_wrapper {
        margin: 0 !important;
    }
    
    .gfield.pull-right {
        @media (max-width: 640px) {
            float: none !important;
        }
    }
    
    .gform_wrapper, 
    .gform_body {
        width: 100% !important;
        max-width: 100% !important;
        
        label {
            text-transform: uppercase;
            font-weight: 300;
            color: #fff;
            font-size: 24px;
        }
    }
    
    .gform_wrapper label.gfield_label {
        font-weight: 300;
        color: #1a2d2b;
    }
    
    .gform_wrapper input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="button"]):not([type="image"]):not([type="file"]), 
    .ginput_container input, .ginput_container select, .ginput_container textarea {
        background: #ffffff;
        color: #1a2d2b;
        border: 0 none;
        font-weight: 300;
        text-transform: uppercase;
        width: 100%;
        font-size: 24px;
        height: 40px;
        padding: 0 8px;
        border-radius: 0;
    }
    
    .gform_wrapper .top_label li.gfield.gf_left_half textarea, .gform_wrapper .top_label li.gfield.gf_right_half textarea {
        width: 100%;
    }
    
    .gfield_select {
        width: 100%;
        width: calc(100% - 105px) !important;
        float: right;
        
        @media (max-width: 640px) {
            margin-bottom: 12px;
        }
    }
    
    .haslabel {
        label {
            position: absolute;
            margin: 5px 0;
            display: block !important;
            font-size: 24px !important;
            width: 105px !important; 
            text-align: left;
            float: left;
            padding: 0 15px;
            
            @media (min-width: 641px) {
                margin: 12px 0;
            }
        }
        
        .ginput_container {
            margin-left: 85px;
            position: relative;
            padding: 0;
            
            @media (min-width: $screen-md-min) {
                width: 55%;
                margin-left: 0px;
                float: left;
            }
            
            &:after {
                @include retina-sprite($mail-list-dropdown-group);
                content: " ";
                position: absolute;
                right: 8px;
                top: 15px;
            }
            
            select {
                appearance:none;
                -moz-appearance:none; /* Firefox */
                -webkit-appearance:none; /* Safari and Chrome */
                -webkit-border-radius: 0px;
            }
        }
    }
    

    
    .gform_wrapper ul li.gfield {
        clear: none !important;
        margin-top: 12px;
    }
    
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #1a2d2b;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: #1a2d2b;
      opacity: 1;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: #1a2d2b;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: #1a2d2b;
      opacity: 1;
    }

    .ginput_container span {
        margin-bottom: 15px;
        display: inline-block;
        
         label {
            display: none !important;
        }
        
        &.ginput_full {
            width: 100% !important;
        }
    }
    
    .gform_footer {
        text-align: center;
    
        .gform_button {
            border: 5px solid #1a2d2b;
            max-width: 400px;
            width: 100% !important;
            font-size: 20px !important;
            letter-spacing: 2px;
            font-weight: 700;
            text-transform: uppercase;
            color: #ffffff !important;
            background: #1a2d2b;
            border-radius: 0;
            padding: 2px 30px !important;
            margin: 8px auto 15px;
            display: table;
            @include transition(0.3s);
        
            &:hover {
                background: #ffffff;
                color: #1a2d2b !important;
                @include transition(0.3s);
            }
        }
    }
}