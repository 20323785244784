aside {
    padding: 0;
    
    @media (max-width: $screen-md-min - 1) {
        margin-top: 40px;
    }
    
    .current-menu-item a {
        color: #18c368;
    }
    
    .sidebar-block {
        margin-bottom: 30px;
    }
    
    h3, .h3 {
        margin-top: 0;
        color: #35845a;
        font-weight: 500;
        font-size: 25px;
    }
    
    ul {
        list-style-type: none;
        padding: 0;
    }
    
    li {
        margin: 10px 0;
        position: relative;
        padding-left: 16px;
        
        &:before {
            @include retina-sprite($bullet-group);
            content: " ";
            position: absolute;
            top: 12px;
            left: 0;
        }
        
        a {
            color: #35845a;
            font-weight: 500;
            font-size: 22px;
        }
    }
}