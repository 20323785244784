// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

.validation_error {
    background: #ffdfe0;
    text-align: center;
}

.validation_message {
    width: 100% !important;
    text-align: center;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #4a6f50;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #4a6f50;
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #4a6f50;
}
:-moz-placeholder { /* Firefox 18- */
  color: #4a6f50;
  opacity: 1;
}

.checkbox label:before {
    display: block;
    content: " ";
    position: absolute;
    @include retina-sprite($checkbox-group);
    left: 0;
    top: -2px;
}

.checkbox input:checked + label:before {
    @include retina-sprite($checked-group);
    left: 0;
    top: -2px;
}

.main {

    .gform_wrapper {
        margin: 0 !important;
    }
    
    .gform_wrapper, 
    .gform_body {
        width: 100% !important;
        max-width: 100% !important;
        
        label {
            color: #8a0f55;
            text-transform: uppercase;
        }
    }
    
    .gfield_label {
        color: #4d4a4a !important;
        text-transform: none !important;
        font-size: 16px;
        font-weight: 400 !important;
        display: none !important;
    }
    
    .gfield_required {
        display: none !important;
    }
    
    .ginput_container span {
        margin-bottom: 15px;
        display: inline-block;
        
         label {
            display: none !important;
        }
        
        &.ginput_full {
            width: 100% !important;
        }
    }
    
    .gform_wrapper .ginput_complex .ginput_full input[type="email"], 
    .gform_wrapper .ginput_complex .ginput_full input[type="number"], 
    .gform_wrapper .ginput_complex .ginput_full input[type="password"], 
    .gform_wrapper .ginput_complex .ginput_full input[type="tel"], 
    .gform_wrapper .ginput_complex .ginput_full input[type="text"], 
    .gform_wrapper .ginput_complex .ginput_full input[type="url"] {
        width: 100% !important;
    }
    
    .gform_wrapper .ginput_complex .ginput_left, .gform_wrapper .ginput_complex .ginput_right {
        width: 49.2% !important;
    }
    
    div.ginput_container_name span {
        padding-right: 12px;
    }
    
    div.ginput_container_name span:last-child {
        padding-right: 0;
    }
    
    input, textarea, select {
        background: #f0f4f6;
        border: 0 none;
        padding: 10px 15px !important;
        font-weight: 400;
        font-size: 20px !important;
        width: 100% !important;
        border-radius: 0;
        -webkit-appearance: none;
        color: #4a6f50;
        text-transform: uppercase;
        letter-spacing: 2px !important;
    }
    
    .gform_wrapper label.gfield_label + div.ginput_container {
        margin-top: 25px !important;
    }
    
    .gform_wrapper .no-top-margin label.gfield_label + div.ginput_container {
        @media (min-width: $screen-md-min) {
            margin-top: 0 !important;
        }
    }
    
    .gform_wrapper ul li.gfield {
        clear: none;
        
        @media (max-width: $screen-md-min - 1) {
            padding: 0 !important;
        }
    }
    
    li.gfield {
        
    }
    
    .gfield_radio input {
        display: none;
    }
    
    .ginput_container_radio .gfield_radio li label {
        margin: 0 0 6px 40px !important;
    }

    .ginput_container_radio .gfield_radio li label:before {
        display: block;
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        @include retina-sprite($checkbox-group);
    }

    .ginput_container_radio .gfield_radio li input:checked + label:before {
        @include retina-sprite($checked-group);
    }
    
    .gform_footer {
        text-align: right;
    }
    
    .gform_button {
        border: 5px solid #35845a;
        max-width: 185px;
        font-size: 20px !important;
        letter-spacing: 2px;
        font-weight: 700;
        text-transform: uppercase;
        color: #35845a;
        background: #ffffff;
        padding: 10px 5px !important;
        margin-top: 40px;
        margin-right: 15px;
        @include transition(0.3s);
        
        &:hover {
            background: #35845a;
            color: #ffffff;
            @include transition(0.3s);
        }
    }
}