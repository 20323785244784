.text-white {
    color: #ffffff;
}

.text-green {
    color: #35845a;
}

a {
    &:hover {
        text-decoration: none;
    }
}  

h1, .h1 {
    font-size: 45px;
}

p {
    margin: 0 0 22px;
}

hr {
    border-top: 1px solid #b3d2c1;
    margin-top: 32px;
    margin-bottom: 32px;
}