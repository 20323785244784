#search {
    background: #ffffff;
    
    input {
        border: 0 none;
        border-radius: 0;
        font-weight: 700;
        height: 42px;
        letter-spacing: 2.5px;
        
        @media (min-width: $screen-sm-min) {
            width: 195px;
            font-size: 10px;
        }
    
        @media (min-width: $screen-md-min) {
            width: 283px;
            font-size: 12px;
        }
    }
    
    input[type="text"], input[type="email"], input[type="search"], input[type="password"] {
        -webkit-appearance: caret;
        -moz-appearance: caret; /* mobile firefox too! */
    }
} 