.contact-info {
    font-family: 'proxima-nova';
    font-weight: 300;
    font-size: 22px;
    padding: 38px 0 34px;
    
    @media (min-width: $screen-md-min) {
        height: 105px;
    }

    .address {
        font-size: 22px;
        position: relative;
        padding-left: 40px;
        
        @media (min-width: $screen-lg-min) {
            font-size: 27px;
        }
        
        &:before {
            @include retina-sprite($contact-location-group);
            content: " ";
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    
    .phone {
        font-size: 22px;
        position: relative;
        padding-left: 40px;
        
        @media (max-width: $screen-md-min - 1) {
            margin-top: 20px;
        }
        
        @media (min-width: $screen-lg-min) {
            font-size: 27px;
        }
        
        &:before {
            @include retina-sprite($phone-group);
            content: " ";
            position: absolute;
            left: 0;
            top: 4px;
        }
    }
}

#property-sf {
    color: #34f18d;
}

aside {
    .department-contact {
        margin-bottom: 35px;
        
        .contact-detail {
            color: #7d7d7d;
            font-weight: 300;
            font-size: 19px;
            line-height: 1.3;
            
            a {
                color: #2bb676;
                font-weight: 500;
            }
        }
    }
}

#gmap-contact {
    height: 400px;
    overflow: hidden;
}

