.page-title {

    @media (min-width: $screen-md-min) {
        margin-top: 30px;
    }
    
    @media (min-width: $screen-md-min) {
        margin-top: 40px;
    }
    
    h1, .h1 {
        @media (min-width: $screen-md-min) {
            font-size: 60px;
        }
    }   
}

.page-header {
    border-bottom: 0;
    background: #35845a;
    margin: 0;
    padding: 0;
    
    > .container {
        @media (min-width: $screen-md-min) {
            height: 105px;
        }
        
        &:after {
            width: 0; 
            height: 0; 
            border-left: 22px solid transparent;
            border-right: 22px solid transparent;
            border-top: 22px solid #35845a;
            content: " ";
            position: absolute;
            z-index: 10;
        }
        
        h3, .h3 {
            font-weight: 500;
            font-size: 34px;
            display: inline-block;
        }
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 32px;
        margin-top: 34px;
    }
}

.page {
    #intro {
        margin-top: 20px;
    }
}
        
#wrapper {
    position: relative;
    padding: 75px 0 100px;
    
    .content {
    
        .main {
            background: #fff;
            padding: 0;
            font-size: 16px;
            color: #525957;
            font-weight: 400;
            
            #loginform {
                max-width: 320px;
                margin: 0 auto;
                background: #35845a;
                padding: 26px 24px 75px;
                
                label, input {
                    width: 100%;
                    color: #fff;
                    font-weight: 400;
                    font-size: 15px;
                }
                
                input {
                    height: 40px;
                }
                
                .forgetmenot {
                    float: left;
                }
                
                #rememberme {
                    width: auto !important;
                    height: auto !important;
                    -webkit-appearance:checkbox;
                }
                
                .submit {
                    margin: 0;
                }
                
                .btn {
                    border: 5px solid #fff;
                    color: #fff;
                    font-weight: 700;
                    background: #35845a;
                    height: 40px;
                    padding: 0 12px !important;
                    float: right;
                    width: 100px !important;
                    font-size: 15px !important;
                    margin: 0;
                    
                    &:hover {
                        background: #fff;
                        color: #35845a;
                        @include transition(0.3s);
                    }
                }
            }

            .btn {
                background: #edf3f7;
                border-radius: 0;
                font-size: 18px;
                padding: 10px 100px 10px 15px;
                position: relative;
                margin: 12px 0;
                height: 60px;
                line-height: 2;
                text-align: left;
            
                @media (min-width: $screen-sm-min) {
                    font-size: 22px;
                    padding: 10px 100px 10px 40px;
                    line-height: 1.6;
                    text-align: center;
                }
            
                @media (min-width: $screen-md-min) {
                    font-size: 25px;
                    line-height: 1.5;
                }
            } 
        
            h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
                font-weight: 700;
                padding: 0;
                color: #35845a;
            }
            
            h3, .h3 {
                margin-top: 20px;
                font-size: 25px;
                margin-bottom: 22px;
            }
        }
    }
}