.page-template-template-currentportfolio {
    header {
        height: 335px;
    }
}

.portfolio {
    .content {
        padding: 150px 0 200px;
    }
}

.portfolio-info {
    border-bottom: 1px solid #fff;
    
    @media (min-width: $screen-md-min) {
        border-right: 1px solid #fff;
        border-bottom: 0;
    }
    
    &:last-child {
        border-right: 0;
        border-bottom: 0;
    }
    
    h4 {
        font-weight: 700;
        margin-top: 20px;
        
        @media (min-width: $screen-md-min) {
            margin-top: 10px;
        }
    }
    
    > div {
        font-size: 14px;
        line-height: 1.2;
        
        p {
            margin: 0 0 20px;
            
            @media (min-width: $screen-md-min) {
                margin: 0 0 10px;
            }
        }
    }
}

.property-header {
    border-bottom: 0;
    background: #35845a;
    margin: 0;
    height: 530px;
    position: relative;
    
    @media (min-width: $screen-md-min) {
        height: 200px;
    }
    
    &:after {
        width: 0; 
        height: 0; 
        bottom: -32px;
        left: 50%;
        margin-left: -32px;
        border-left: 32px solid transparent;
        border-right: 32px solid transparent;
        border-top: 32px solid #35845a;
        content: " ";
        position: absolute;
        z-index: 10;
    }
}

#filter {
    @media (max-width: $screen-md-min - 1) {
        padding: 0;
    }
        
    #filter-box {
        background: #fff;
        max-width: 915px;
        width: 100%;
        margin: -75px auto 20px;
        padding: 20px 15px;
        display: inline-block;
        -webkit-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 0px 21px 0px rgba(0,0,0,0.15);
        
        @media (min-width: $screen-md-min) {
            padding: 20px 30px;
            display: table;
        }
    }
    
    #filter-btn {
        padding: 4px 40px;
    }
    
    span.filter-label {
        font-family: 'proxima-nova';
        font-weight: 700;
        color: #3c3e3f;
        position: absolute;
        margin: 10px 0;
        left: 0;
    }
    
    .select-filter {
        @media (max-width: $screen-md-min - 1) {
            margin-bottom: 10px;
            padding-right: 0;
        }
    }
        
    .select-wrap-1 {
        padding-left: 105px;
    }
    
    .select-wrap-2 {
        padding-left: 42px;
    }
    
    .select-wrap-3 {
        padding-left: 82px;
    }
    
    .checkbox-wrap {
        padding-left: 135px;
    }
        
    select {
        height: 42px;
        color: #35845a;
        background: #fff;
        border: 0 none;
        font-size: 25px;
        font-family: 'proxima-nova';
        font-weight: 300;
        text-indent: 3px;
        appearance:none;
        -moz-appearance:none; /* Firefox */
        -webkit-appearance:none; /* Safari and Chrome */
        
        option {
            padding: 0 8px;
        }
    }
    
    .styled-select {
        border: 1px solid #ebebeb;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        
        &:after {
            @include retina-sprite($filter-dropdown-group);
            content: " ";
            position: absolute;
            right: 10px;
            top: 16px;
        }
    }
    
    .checkbox {
        label {
            font-size: 15px;
            color: #35845a;
        }
    }
    
    .ui-slider {
        background: #3c3e3f;
        height: 3px;
        margin: 10px 0px;
    }
    
    .ui-slider-range {
        background: #18c368;
    }
    
    .ui-slider-horizontal .ui-slider-handle {
        margin-left: -15px;
        margin-top: -5px;
    }
    
    .ui-slider-handle.ui-state-default.ui-corner-all {
        background: #18c368 none repeat scroll 0 0;
        display: inline-block;
        height: 23px;
        width: 23px;
        position: relative;
        z-index: 10;
        border-radius: 50%;
    }
    
    .portfolio-slider {
        @media (max-width: $screen-md-min - 1) {
            margin-top: 35px;
        }
    }
}

.property-list {
    .portfolio-pagination {
        margin-top: 20px;
        
        .pager {
            
            span {
                height: 20px;
                width: 20px;
                border-radius: 50%;
                display: inline-block;
                font-size: 14px;
                
                &:hover {
                    cursor: pointer;
                }
            }
            
            &.active span {
                background: #35845a;
                color: #fff;
            }
        }
    }
}

#transaction-type { 
    margin: 10px 0;
    
    span {
        display: inline-block;
        
        text-align: left;
        width: 150px;
    }
    
    .checkbox {
        display: inline-block;
        
        text-align: right;
        padding: 0 0 0 15px;
        
        @media (max-width: $screen-md-min - 1) {
            width: 100%;
            text-align: left;
        }
        
        input {
            display: none;
        }
    }
}

#sort-properties .fail-message{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  
  -webkit-transition: 150ms;
  -moz-transition: 150ms;
  transition: 150ms;
}
  
#sort-properties .fail-message:before{
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}

#sort-properties .fail-message span{
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 700;
}

#sort-properties.fail .fail-message{
  opacity: 1;
  pointer-events: auto;
}

#sort-properties .mix{
	display: none;
	margin-bottom: 50px;
	
	@media (min-width: $screen-sm-min) {
        max-height: 365px;
    }

    @media (min-width: $screen-md-min) {
        max-height: 350px;
    }

    @media (min-width: $screen-lg-min) {
        min-height: 385px;
    }
	
	.img-wrap {
	    position: relative;
	    overflow: hidden;
	    
        @media (min-width: $screen-sm-min) {
            max-height: 230px;
        }
    
        @media (min-width: $screen-md-min) {
            max-height: 205px;
        }
    
        @media (min-width: $screen-lg-min) {
            max-height: 250px;
        }
	}
	
	img {
	    min-width: 100%;
	}   
	
	.prop-ribbon {
	    position: absolute;
	    bottom: 0;
	    width: 100%;
	    color: #ffffff;
	    font-weight: 600;
	    letter-spacing: 3px;
	    font-size: 15px;
	    padding: 5px 0;
	    @include transition(0.2s);
	    
	    &.sale {
	        background: #35845a;
	    }
	    &.lease {
	        background: #40d686;
	    }
	    &.refinance {
	        background: #40d686;
	    }
	}
	
	&:hover .prop-ribbon {
        bottom: -32px;
        @include transition(0.2s);
    }
    
    .location-name {
        font-weight: 600;
        font-size: 28px;
        margin-bottom: 5px;
    }
    
    .address {
        font-weight: 300;
        font-size: 20px;
    }
    
    &:hover .property-overlay {
        height: 100%;
        @include transition(0.2s);
    }
    
    &:hover .view-prop {
        bottom: 20px;
        @include transition(0.2s);
    }
    
    .view-prop {
        position: absolute;
        width: 100%;
        bottom: -50px;
        font-size: 34px;
        font-weight: 300;
        text-indent: 15px;
        left: 0;
        text-align: left;
        @include transition(0.2s);
        
        &:hover {
            color: #ffffff;
        }
        
        .property-arrow {
            @include retina-sprite($property-arrow-group);
            margin-bottom: 20px;
            position: absolute;
            right: 15px;
            top: 0;
        }
    }

	.property-overlay {
	    background: rgba(46,185,111,0.94);
	    //display: none;
        height: 0;
        position: absolute;
        width: 100%;
        bottom: 0;
        @include transition(0.2s);
        
        .property-preview {
            display: block;
            height: 100%;
            padding: 0;
            position: relative;
            width: 100%;
            text-align: left;
                
            .address-1 {
                font-weight: 300;
                font-size: 25px;
                line-height: 1;
                
                @media (min-width: $screen-lg-min) {
                    font-size: 25px;
                }
            }
        
            .address-2 {
                font-weight: 300;
                font-size: 18px;
                margin: 10px 0;
                
                @media (min-width: $screen-lg-min) {
                    font-size: 20px;
                }
            }
        
            .sqft {
                font-weight: 700;
                font-size: 15px;
            }
        }
	}
}