@mixin transition($time) {
    -moz-transition:    all $time ease;
    -o-transition:      all $time ease;
    -webkit-transition: all $time ease;
    transition:         all $time ease;
}

@mixin fadein($time) {
    @media (min-width: $screen-sm-min) {
        -webkit-animation: fadein $time; /* Safari and Chrome */
           -moz-animation: fadein $time; /* Firefox */
            -ms-animation: fadein $time; /* Internet Explorer */
             -o-animation: fadein $time; /* Opera */
                animation: fadein $time;
    }
}
