// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #4d4a4a;
$btn-break:             1400px;

$footer-bg:             #35845a;
$footer-nav-bg:         #d8edf3; 
