header {
    background: #ffffff;
    //background-size: cover;
    //height: 320px;
    
    @media (max-width: $screen-md-min - 1) {
        padding-bottom: 75px;
    }
        
    @media (min-width: $screen-md-min) {
        height: 200px;
    }

    .inner-header {
        position: relative;

        #logo {
            display: inline-block;
            
            @media (min-width: $screen-md-min) {
                float: left;
            }
            
            img {
                margin: 50px 0 10px 15px;
                height: 79px;
                width: 205px;
            }
        } 
        
        #social-nav {
            padding: 30px 0 0;
            
            ul {
                margin-bottom: 0;
            }
            
            li {
                width: 20px;
                padding: 0;
                margin: 0 5px;
                
                & > a {
                    text-indent: -9999px;
                    display: block;
                }
                
                &.btn {
                    width: auto;
                    padding: 4px 8px;
                    font-size: 14px;
                    border: 1px solid #35845a;
                    margin-top: -3px;
                    
                    & > a {
                        text-indent: 0;
                        display: block;
                        color: #35845a;
                    }
                    
                    &:hover a {
                        color: #ffffff;
                    }
                }
                
                &.facebook a {
                    @include retina-sprite($facebook-group);
                }
                
                &.twitter a {
                    @include retina-sprite($twitter-group);
                }
                
                &.linkedin a {
                    @include retina-sprite($linkedin-group);
                }
            }
        }
        
        #mobile-social-nav {
            height: 85px;
            background: #35845a;
            width: 100%;
            
            li {
                width: 20px;
                padding: 0;
                margin: 0 5px;
                
                & > a {
                    text-indent: -9999px;
                    display: block;
                    padding: 0;
                    
                    &:hover {
                        background: transparent;
                    }
                }
                
                &.btn {
                    width: auto;
                    padding: 4px 8px;
                    font-size: 14px;
                    border: 1px solid #fff;
                    margin-top: -3px;
                    
                    @media (max-width: $screen-md-min - 1) {
                        //display: block;
                        //margin: 20px 15px;
                    }
                    
                    & > a {
                        text-indent: 0;
                        display: block;
                        color: #fff;
                    }
                    
                    &:hover a {
                        color: #3c3e3f;
                    }
                }
                
                &.facebook a {
                    @media (max-width: $screen-md-min - 1) {
                        @include retina-sprite($facebook-white-group);
                    }
                    @include retina-sprite($facebook-group);
                }
                
                &.twitter a {
                    @media (max-width: $screen-md-min - 1) {
                        @include retina-sprite($twitter-white-group);
                    }
                    @include retina-sprite($twitter-group);
                }
                
                &.linkedin a {
                    @media (max-width: $screen-md-min - 1) {
                        @include retina-sprite($linkedin-white-group);
                    }
                    @include retina-sprite($linkedin-group);
                }
            }
        }
    }
}