.navbar-toggle { 
    padding: 15px 0;
    margin-top: 60px;
    
    .icon-bar {
        border-radius: 0;
        height: 5px;
        width: 35px;
        background: #35845a;
        margin-bottom: 6px;
    }
}

#navbar {
    clear: right; 
    padding-right: 0;
    margin-top: 20px !important;
    
    @media (max-width: $screen-md-min - 1) {
        background: #35845a;
        width: 100%;
        position: absolute;
        z-index: 1000;
        border-top: none;
        padding: 0;
        margin: 0;
        
        .navbar-nav {
            margin: 7px 0;
        }
    }
    
    ul {
        li > a {
            color: #ffffff;
            font-size: 16px;
            font-family: 'proxima-nova';
            font-weight: 500;
            
            @media (max-width: $screen-md-min - 1) {
                text-align: center;
                padding: 20px 15px;
            }
            
            @media (min-width: $screen-md-min) {
                line-height: 23px;
                display: block;
                font-size: 14px;
                padding: 5px 12px;
                color: #35845a;
            }
            
            @media (min-width: $screen-lg-min) {
                line-height: 23px;
                display: block;
                font-size: 16px;
                padding: 5px 15px;
            }
            
            &:hover,
            &:focus {
                @include transition(0.3s);
                background: transparent;
            }
        }
        
        li.current-menu-item {
            a {
                background: transparent;
            }
        }
        
        li.menu-item-has-children:hover > .sub-menu {
            display: block;
            @include fadein(300ms);
        }
        
        .sub-menu {
            padding: 0;
            z-index: 1000000000;
            width: 100%;
            
            @media (min-width: $screen-md-min) {
                display: none;
                position: absolute;
                min-width: 200px;
                    left: 50%;
                    margin-left: -100px;
            }
            
            li {
                width: 100%;
                list-style-type: none;
                
                & > a {
                    background: #35845a;
                    font-size: 14px;
                    padding: 12px 18px;
                    color: #ffffff;
                    
                    &:hover {
                        background: lighten(#35845a, 2%);
                    }
                    
                    @media (max-width: $screen-md-min - 1) {
                        display: block;
                    }
                    
                    @media (min-width: $screen-md-min) {
                        font-size: 14px;
                    }
                    
                    @media (min-width: $screen-lg-min) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}