footer {
    .footer-content {
        background: $footer-bg;
        font-family: 'proxima-nova';
        font-weight: 300;
        min-height: 200px;
        padding-top: 55px;
        padding-bottom: 15px;
        color: #ffffff;
        font-size: 26px;
        line-height: 1;
        
        .footer-logo {
            @include retina-sprite($footer-logo-group);
            position: absolute;
            left: 50%;
            margin-left: -102.5px;
        }
        
        .logo-border-left {
            width: 50%;
            padding-right: 100px;
            display: inline-block;
            float: left;
        }
        
        .logo-border-right {
            width: 50%;
            padding-left: 100px;
            display: inline-block;
            float: left;
        }
        
        .logo-border {
            background: #ffffff;
            width: 100%;
            display: block;
            height: 3px;
            margin-top: 48px;
            margin-bottom: 50px;
        }
    }

    .footer-info {
        background: #35845a;
        color: #284f49;
        padding: 25px 0 65px;
        font-family: 'proxima-nova';
        font-weight: 400;
        font-size: 18px;
    }
}