.btn {
    border: 5px solid #35845a;
    color: #35845a;
    padding: 5px 10px;
    border-radius: 0;
    background: transparent;
    text-transform: uppercase;
    font-family: 'proxima-nova';
    font-weight: 700;
    font-size: 22px;
    @include transition(0.3s);
    
    &:hover, &:focus {
        background: #35845a;
        color: #ffffff;
        text-decoration: none;
        @include transition(0.3s);
    }
}

.btn-white {
    border: 5px solid #ffffff;
    color: #ffffff;
    
    &:hover, &:focus {
        background: #ffffff;
        color: #35845a;
        text-decoration: none;
        @include transition(0.3s);
    }
}

.btn-green {
    border: 5px solid #35845a;
    background: #35845a;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 0;
    text-transform: uppercase;
    font-family: 'proxima-nova';
    font-weight: 700;
    font-size: 18px;
    @include transition(0.3s);
    
    @media (min-width: $screen-sm-min) {
        font-size: 24px;
    }
    
    @media (min-width: $screen-md-min) {
        font-size: 28px;
    }
    
    &:hover, &:focus {
        background: #ffffff;
        color: #35845a;
        text-decoration: none;
        @include transition(0.3s);
    }
}

.btn-left {

     @media (max-width: $screen-sm-min - 1) {
        margin-bottom: 40px;
     }
    
    @media (min-width: $screen-sm-min) {
        margin-right: 25px;
    }
    @media (min-width: $screen-md-min) {
        margin-left: 50px;
    }
}

.btn-right {
    @media (min-width: $screen-sm-min) {
        margin-left: 25px;
    }
    @media (min-width: $screen-md-min) {
        margin-left: 50px;
    }
}

.btn-left,
.btn-right {
    display: block;
    
    
    @media (min-width: $screen-sm-min) {
        display: inline-block;
    }
}
        