#investments {

    .investment {
        border-bottom: 1px solid #b3d2c1;
        margin-top: 25px;
        margin-bottom: 20px;
        padding-bottom: 5px;
        
        .investment-overlay {
            position: relative;
            margin-bottom: 25px;
            display: inline-block;
            
            .h2-bg {
                width: 100%;
                position: absolute;
                bottom: 0px;
                left: 0px;
                height: 100px;
                background: #051c10;
                background: -webkit-linear-gradient(transparent, #051c10); /* For Safari 5.1 to 6.0 */
                background: -o-linear-gradient(transparent, #051c10); /* For Opera 11.1 to 12.0 */
                background: -moz-linear-gradient(transparent, #051c10); /* For Firefox 3.6 to 15 */
                background: linear-gradient(transparent, #051c10); /* Standard syntax */
                
                @media (min-width: $screen-md-min) {
                    height: 180px;
                }
            
                h2, .h2 {
                  margin: 0 !important;
                  color: #18c368 !important; 
                  font-weight: 400 !important;
                  position: absolute;
                  bottom: 15px;
                  left: 15px;
                  font-size: 38px;
                  
                  @media (max-width: $screen-md-min - 1) {
                    font-size: 22px;
                  }
                }
            }
        }
    }

}