.post {
     border-bottom: 1px solid #b3d2c1;
     padding: 26px 0;
     
     &:last-child {
        border-bottom: 0;
     }
     
     .entry-meta {
        color: #b7ccc2;
        
         a {
            color: #b7ccc2;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 19px;
         }
     }
     
    .h1, .h2, .h3, h1, h2, h3 {
        margin: 0;
        
        a {
            font-weight: 700;
            padding-top: 20px;
            color: #35845a;
            text-transform: none;
        }
    }
    
    h2, .h2 {
        font-size: 25px;
        padding: 8px 0 !important;
        margin: 0;
    }
    
    .read-more {
        color: #2bb676;
        font-weight: 500;
        font-size: 19px;
        display: block;
    }
}