/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$arrow-left-name: 'arrow-left';
$arrow-left-x: 0px;
$arrow-left-y: 100px;
$arrow-left-offset-x: 0px;
$arrow-left-offset-y: -100px;
$arrow-left-width: 41px;
$arrow-left-height: 77px;
$arrow-left-total-width: 251px;
$arrow-left-total-height: 243px;
$arrow-left-image: '../images/spritesheet.png';
$arrow-left: (0px, 100px, 0px, -100px, 41px, 77px, 251px, 243px, '../images/spritesheet.png', 'arrow-left', );
$arrow-right-name: 'arrow-right';
$arrow-right-x: 41px;
$arrow-right-y: 100px;
$arrow-right-offset-x: -41px;
$arrow-right-offset-y: -100px;
$arrow-right-width: 41px;
$arrow-right-height: 77px;
$arrow-right-total-width: 251px;
$arrow-right-total-height: 243px;
$arrow-right-image: '../images/spritesheet.png';
$arrow-right: (41px, 100px, -41px, -100px, 41px, 77px, 251px, 243px, '../images/spritesheet.png', 'arrow-right', );
$brochure-name: 'brochure';
$brochure-x: 187px;
$brochure-y: 100px;
$brochure-offset-x: -187px;
$brochure-offset-y: -100px;
$brochure-width: 15px;
$brochure-height: 30px;
$brochure-total-width: 251px;
$brochure-total-height: 243px;
$brochure-image: '../images/spritesheet.png';
$brochure: (187px, 100px, -187px, -100px, 15px, 30px, 251px, 243px, '../images/spritesheet.png', 'brochure', );
$bullet-name: 'bullet';
$bullet-x: 243px;
$bullet-y: 87px;
$bullet-offset-x: -243px;
$bullet-offset-y: -87px;
$bullet-width: 8px;
$bullet-height: 8px;
$bullet-total-width: 251px;
$bullet-total-height: 243px;
$bullet-image: '../images/spritesheet.png';
$bullet: (243px, 87px, -243px, -87px, 8px, 8px, 251px, 243px, '../images/spritesheet.png', 'bullet', );
$checkbox-name: 'checkbox';
$checkbox-x: 109px;
$checkbox-y: 148px;
$checkbox-offset-x: -109px;
$checkbox-offset-y: -148px;
$checkbox-width: 27px;
$checkbox-height: 27px;
$checkbox-total-width: 251px;
$checkbox-total-height: 243px;
$checkbox-image: '../images/spritesheet.png';
$checkbox: (109px, 148px, -109px, -148px, 27px, 27px, 251px, 243px, '../images/spritesheet.png', 'checkbox', );
$checked-name: 'checked';
$checked-x: 82px;
$checked-y: 148px;
$checked-offset-x: -82px;
$checked-offset-y: -148px;
$checked-width: 27px;
$checked-height: 27px;
$checked-total-width: 251px;
$checked-total-height: 243px;
$checked-image: '../images/spritesheet.png';
$checked: (82px, 148px, -82px, -148px, 27px, 27px, 251px, 243px, '../images/spritesheet.png', 'checked', );
$contact-location-name: 'contact-location';
$contact-location-x: 32px;
$contact-location-y: 177px;
$contact-location-offset-x: -32px;
$contact-location-offset-y: -177px;
$contact-location-width: 26px;
$contact-location-height: 34px;
$contact-location-total-width: 251px;
$contact-location-total-height: 243px;
$contact-location-image: '../images/spritesheet.png';
$contact-location: (32px, 177px, -32px, -177px, 26px, 34px, 251px, 243px, '../images/spritesheet.png', 'contact-location', );
$download-small-name: 'download-small';
$download-small-x: 237px;
$download-small-y: 144px;
$download-small-offset-x: -237px;
$download-small-offset-y: -144px;
$download-small-width: 9px;
$download-small-height: 18px;
$download-small-total-width: 251px;
$download-small-total-height: 243px;
$download-small-image: '../images/spritesheet.png';
$download-small: (237px, 144px, -237px, -144px, 9px, 18px, 251px, 243px, '../images/spritesheet.png', 'download-small', );
$envelope-share-name: 'envelope-share';
$envelope-share-x: 83px;
$envelope-share-y: 177px;
$envelope-share-offset-x: -83px;
$envelope-share-offset-y: -177px;
$envelope-share-width: 25px;
$envelope-share-height: 25px;
$envelope-share-total-width: 251px;
$envelope-share-total-height: 243px;
$envelope-share-image: '../images/spritesheet.png';
$envelope-share: (83px, 177px, -83px, -177px, 25px, 25px, 251px, 243px, '../images/spritesheet.png', 'envelope-share', );
$explore-small-name: 'explore-small';
$explore-small-x: 237px;
$explore-small-y: 127px;
$explore-small-offset-x: -237px;
$explore-small-offset-y: -127px;
$explore-small-width: 13px;
$explore-small-height: 17px;
$explore-small-total-width: 251px;
$explore-small-total-height: 243px;
$explore-small-image: '../images/spritesheet.png';
$explore-small: (237px, 127px, -237px, -127px, 13px, 17px, 251px, 243px, '../images/spritesheet.png', 'explore-small', );
$facebook-share-name: 'facebook-share';
$facebook-share-x: 133px;
$facebook-share-y: 177px;
$facebook-share-offset-x: -133px;
$facebook-share-offset-y: -177px;
$facebook-share-width: 25px;
$facebook-share-height: 25px;
$facebook-share-total-width: 251px;
$facebook-share-total-height: 243px;
$facebook-share-image: '../images/spritesheet.png';
$facebook-share: (133px, 177px, -133px, -177px, 25px, 25px, 251px, 243px, '../images/spritesheet.png', 'facebook-share', );
$facebook-white-name: 'facebook-white';
$facebook-white-x: 41px;
$facebook-white-y: 216px;
$facebook-white-offset-x: -41px;
$facebook-white-offset-y: -216px;
$facebook-white-width: 20px;
$facebook-white-height: 20px;
$facebook-white-total-width: 251px;
$facebook-white-total-height: 243px;
$facebook-white-image: '../images/spritesheet.png';
$facebook-white: (41px, 216px, -41px, -216px, 20px, 20px, 251px, 243px, '../images/spritesheet.png', 'facebook-white', );
$facebook-name: 'facebook';
$facebook-x: 158px;
$facebook-y: 177px;
$facebook-offset-x: -158px;
$facebook-offset-y: -177px;
$facebook-width: 20px;
$facebook-height: 20px;
$facebook-total-width: 251px;
$facebook-total-height: 243px;
$facebook-image: '../images/spritesheet.png';
$facebook: (158px, 177px, -158px, -177px, 20px, 20px, 251px, 243px, '../images/spritesheet.png', 'facebook', );
$filter-dropdown-name: 'filter-dropdown';
$filter-dropdown-x: 61px;
$filter-dropdown-y: 216px;
$filter-dropdown-offset-x: -61px;
$filter-dropdown-offset-y: -216px;
$filter-dropdown-width: 20px;
$filter-dropdown-height: 12px;
$filter-dropdown-total-width: 251px;
$filter-dropdown-total-height: 243px;
$filter-dropdown-image: '../images/spritesheet.png';
$filter-dropdown: (61px, 216px, -61px, -216px, 20px, 12px, 251px, 243px, '../images/spritesheet.png', 'filter-dropdown', );
$flex-left-name: 'flex-left';
$flex-left-x: 141px;
$flex-left-y: 100px;
$flex-left-offset-x: -141px;
$flex-left-offset-y: -100px;
$flex-left-width: 46px;
$flex-left-height: 46px;
$flex-left-total-width: 251px;
$flex-left-total-height: 243px;
$flex-left-image: '../images/spritesheet.png';
$flex-left: (141px, 100px, -141px, -100px, 46px, 46px, 251px, 243px, '../images/spritesheet.png', 'flex-left', );
$flex-right-name: 'flex-right';
$flex-right-x: 205px;
$flex-right-y: 0px;
$flex-right-offset-x: -205px;
$flex-right-offset-y: 0px;
$flex-right-width: 46px;
$flex-right-height: 46px;
$flex-right-total-width: 251px;
$flex-right-total-height: 243px;
$flex-right-image: '../images/spritesheet.png';
$flex-right: (205px, 0px, -205px, 0px, 46px, 46px, 251px, 243px, '../images/spritesheet.png', 'flex-right', );
$footer-logo-name: 'footer-logo';
$footer-logo-x: 0px;
$footer-logo-y: 0px;
$footer-logo-offset-x: 0px;
$footer-logo-offset-y: 0px;
$footer-logo-width: 205px;
$footer-logo-height: 100px;
$footer-logo-total-width: 251px;
$footer-logo-total-height: 243px;
$footer-logo-image: '../images/spritesheet.png';
$footer-logo: (0px, 0px, 0px, 0px, 205px, 100px, 251px, 243px, '../images/spritesheet.png', 'footer-logo', );
$linkedin-share-name: 'linkedin-share';
$linkedin-share-x: 58px;
$linkedin-share-y: 177px;
$linkedin-share-offset-x: -58px;
$linkedin-share-offset-y: -177px;
$linkedin-share-width: 25px;
$linkedin-share-height: 25px;
$linkedin-share-total-width: 251px;
$linkedin-share-total-height: 243px;
$linkedin-share-image: '../images/spritesheet.png';
$linkedin-share: (58px, 177px, -58px, -177px, 25px, 25px, 251px, 243px, '../images/spritesheet.png', 'linkedin-share', );
$linkedin-white-name: 'linkedin-white';
$linkedin-white-x: 178px;
$linkedin-white-y: 177px;
$linkedin-white-offset-x: -178px;
$linkedin-white-offset-y: -177px;
$linkedin-white-width: 20px;
$linkedin-white-height: 20px;
$linkedin-white-total-width: 251px;
$linkedin-white-total-height: 243px;
$linkedin-white-image: '../images/spritesheet.png';
$linkedin-white: (178px, 177px, -178px, -177px, 20px, 20px, 251px, 243px, '../images/spritesheet.png', 'linkedin-white', );
$linkedin-name: 'linkedin';
$linkedin-x: 198px;
$linkedin-y: 177px;
$linkedin-offset-x: -198px;
$linkedin-offset-y: -177px;
$linkedin-width: 20px;
$linkedin-height: 20px;
$linkedin-total-width: 251px;
$linkedin-total-height: 243px;
$linkedin-image: '../images/spritesheet.png';
$linkedin: (198px, 177px, -198px, -177px, 20px, 20px, 251px, 243px, '../images/spritesheet.png', 'linkedin', );
$location-name: 'location';
$location-x: 0px;
$location-y: 216px;
$location-offset-x: 0px;
$location-offset-y: -216px;
$location-width: 21px;
$location-height: 27px;
$location-total-width: 251px;
$location-total-height: 243px;
$location-image: '../images/spritesheet.png';
$location: (0px, 216px, 0px, -216px, 21px, 27px, 251px, 243px, '../images/spritesheet.png', 'location', );
$mail-list-dropdown-name: 'mail-list-dropdown';
$mail-list-dropdown-x: 205px;
$mail-list-dropdown-y: 166px;
$mail-list-dropdown-offset-x: -205px;
$mail-list-dropdown-offset-y: -166px;
$mail-list-dropdown-width: 18px;
$mail-list-dropdown-height: 11px;
$mail-list-dropdown-total-width: 251px;
$mail-list-dropdown-total-height: 243px;
$mail-list-dropdown-image: '../images/spritesheet.png';
$mail-list-dropdown: (205px, 166px, -205px, -166px, 18px, 11px, 251px, 243px, '../images/spritesheet.png', 'mail-list-dropdown', );
$phone-name: 'phone';
$phone-x: 136px;
$phone-y: 148px;
$phone-offset-x: -136px;
$phone-offset-y: -148px;
$phone-width: 26px;
$phone-height: 26px;
$phone-total-width: 251px;
$phone-total-height: 243px;
$phone-image: '../images/spritesheet.png';
$phone: (136px, 148px, -136px, -148px, 26px, 26px, 251px, 243px, '../images/spritesheet.png', 'phone', );
$popover-arrow-name: 'popover-arrow';
$popover-arrow-x: 205px;
$popover-arrow-y: 46px;
$popover-arrow-offset-x: -205px;
$popover-arrow-offset-y: -46px;
$popover-arrow-width: 39px;
$popover-arrow-height: 41px;
$popover-arrow-total-width: 251px;
$popover-arrow-total-height: 243px;
$popover-arrow-image: '../images/spritesheet.png';
$popover-arrow: (205px, 46px, -205px, -46px, 39px, 41px, 251px, 243px, '../images/spritesheet.png', 'popover-arrow', );
$property-arrow-name: 'property-arrow';
$property-arrow-x: 205px;
$property-arrow-y: 87px;
$property-arrow-offset-x: -205px;
$property-arrow-offset-y: -87px;
$property-arrow-width: 38px;
$property-arrow-height: 40px;
$property-arrow-total-width: 251px;
$property-arrow-total-height: 243px;
$property-arrow-image: '../images/spritesheet.png';
$property-arrow: (205px, 87px, -205px, -87px, 38px, 40px, 251px, 243px, '../images/spritesheet.png', 'property-arrow', );
$slick-next-name: 'slick-next';
$slick-next-x: 0px;
$slick-next-y: 177px;
$slick-next-offset-x: 0px;
$slick-next-offset-y: -177px;
$slick-next-width: 32px;
$slick-next-height: 39px;
$slick-next-total-width: 251px;
$slick-next-total-height: 243px;
$slick-next-image: '../images/spritesheet.png';
$slick-next: (0px, 177px, 0px, -177px, 32px, 39px, 251px, 243px, '../images/spritesheet.png', 'slick-next', );
$slick-prev-name: 'slick-prev';
$slick-prev-x: 205px;
$slick-prev-y: 127px;
$slick-prev-offset-x: -205px;
$slick-prev-offset-y: -127px;
$slick-prev-width: 32px;
$slick-prev-height: 39px;
$slick-prev-total-width: 251px;
$slick-prev-total-height: 243px;
$slick-prev-image: '../images/spritesheet.png';
$slick-prev: (205px, 127px, -205px, -127px, 32px, 39px, 251px, 243px, '../images/spritesheet.png', 'slick-prev', );
$twitter-share-name: 'twitter-share';
$twitter-share-x: 108px;
$twitter-share-y: 177px;
$twitter-share-offset-x: -108px;
$twitter-share-offset-y: -177px;
$twitter-share-width: 25px;
$twitter-share-height: 25px;
$twitter-share-total-width: 251px;
$twitter-share-total-height: 243px;
$twitter-share-image: '../images/spritesheet.png';
$twitter-share: (108px, 177px, -108px, -177px, 25px, 25px, 251px, 243px, '../images/spritesheet.png', 'twitter-share', );
$twitter-slide-name: 'twitter-slide';
$twitter-slide-x: 82px;
$twitter-slide-y: 100px;
$twitter-slide-offset-x: -82px;
$twitter-slide-offset-y: -100px;
$twitter-slide-width: 59px;
$twitter-slide-height: 48px;
$twitter-slide-total-width: 251px;
$twitter-slide-total-height: 243px;
$twitter-slide-image: '../images/spritesheet.png';
$twitter-slide: (82px, 100px, -82px, -100px, 59px, 48px, 251px, 243px, '../images/spritesheet.png', 'twitter-slide', );
$twitter-white-name: 'twitter-white';
$twitter-white-x: 218px;
$twitter-white-y: 177px;
$twitter-white-offset-x: -218px;
$twitter-white-offset-y: -177px;
$twitter-white-width: 20px;
$twitter-white-height: 20px;
$twitter-white-total-width: 251px;
$twitter-white-total-height: 243px;
$twitter-white-image: '../images/spritesheet.png';
$twitter-white: (218px, 177px, -218px, -177px, 20px, 20px, 251px, 243px, '../images/spritesheet.png', 'twitter-white', );
$twitter-name: 'twitter';
$twitter-x: 21px;
$twitter-y: 216px;
$twitter-offset-x: -21px;
$twitter-offset-y: -216px;
$twitter-width: 20px;
$twitter-height: 20px;
$twitter-total-width: 251px;
$twitter-total-height: 243px;
$twitter-image: '../images/spritesheet.png';
$twitter: (21px, 216px, -21px, -216px, 20px, 20px, 251px, 243px, '../images/spritesheet.png', 'twitter', );
$white-dropdown-name: 'white-dropdown';
$white-dropdown-x: 162px;
$white-dropdown-y: 148px;
$white-dropdown-offset-x: -162px;
$white-dropdown-offset-y: -148px;
$white-dropdown-width: 33px;
$white-dropdown-height: 19px;
$white-dropdown-total-width: 251px;
$white-dropdown-total-height: 243px;
$white-dropdown-image: '../images/spritesheet.png';
$white-dropdown: (162px, 148px, -162px, -148px, 33px, 19px, 251px, 243px, '../images/spritesheet.png', 'white-dropdown', );
$arrow-left-2x-name: 'arrow-left@2x';
$arrow-left-2x-x: 0px;
$arrow-left-2x-y: 200px;
$arrow-left-2x-offset-x: 0px;
$arrow-left-2x-offset-y: -200px;
$arrow-left-2x-width: 82px;
$arrow-left-2x-height: 154px;
$arrow-left-2x-total-width: 502px;
$arrow-left-2x-total-height: 486px;
$arrow-left-2x-image: '../images/spritesheet@2x.png';
$arrow-left-2x: (0px, 200px, 0px, -200px, 82px, 154px, 502px, 486px, '../images/spritesheet@2x.png', 'arrow-left@2x', );
$arrow-right-2x-name: 'arrow-right@2x';
$arrow-right-2x-x: 82px;
$arrow-right-2x-y: 200px;
$arrow-right-2x-offset-x: -82px;
$arrow-right-2x-offset-y: -200px;
$arrow-right-2x-width: 82px;
$arrow-right-2x-height: 154px;
$arrow-right-2x-total-width: 502px;
$arrow-right-2x-total-height: 486px;
$arrow-right-2x-image: '../images/spritesheet@2x.png';
$arrow-right-2x: (82px, 200px, -82px, -200px, 82px, 154px, 502px, 486px, '../images/spritesheet@2x.png', 'arrow-right@2x', );
$brochure-2x-name: 'brochure@2x';
$brochure-2x-x: 374px;
$brochure-2x-y: 200px;
$brochure-2x-offset-x: -374px;
$brochure-2x-offset-y: -200px;
$brochure-2x-width: 30px;
$brochure-2x-height: 60px;
$brochure-2x-total-width: 502px;
$brochure-2x-total-height: 486px;
$brochure-2x-image: '../images/spritesheet@2x.png';
$brochure-2x: (374px, 200px, -374px, -200px, 30px, 60px, 502px, 486px, '../images/spritesheet@2x.png', 'brochure@2x', );
$bullet-2x-name: 'bullet@2x';
$bullet-2x-x: 486px;
$bullet-2x-y: 174px;
$bullet-2x-offset-x: -486px;
$bullet-2x-offset-y: -174px;
$bullet-2x-width: 16px;
$bullet-2x-height: 16px;
$bullet-2x-total-width: 502px;
$bullet-2x-total-height: 486px;
$bullet-2x-image: '../images/spritesheet@2x.png';
$bullet-2x: (486px, 174px, -486px, -174px, 16px, 16px, 502px, 486px, '../images/spritesheet@2x.png', 'bullet@2x', );
$checkbox-2x-name: 'checkbox@2x';
$checkbox-2x-x: 218px;
$checkbox-2x-y: 296px;
$checkbox-2x-offset-x: -218px;
$checkbox-2x-offset-y: -296px;
$checkbox-2x-width: 54px;
$checkbox-2x-height: 54px;
$checkbox-2x-total-width: 502px;
$checkbox-2x-total-height: 486px;
$checkbox-2x-image: '../images/spritesheet@2x.png';
$checkbox-2x: (218px, 296px, -218px, -296px, 54px, 54px, 502px, 486px, '../images/spritesheet@2x.png', 'checkbox@2x', );
$checked-2x-name: 'checked@2x';
$checked-2x-x: 164px;
$checked-2x-y: 296px;
$checked-2x-offset-x: -164px;
$checked-2x-offset-y: -296px;
$checked-2x-width: 54px;
$checked-2x-height: 54px;
$checked-2x-total-width: 502px;
$checked-2x-total-height: 486px;
$checked-2x-image: '../images/spritesheet@2x.png';
$checked-2x: (164px, 296px, -164px, -296px, 54px, 54px, 502px, 486px, '../images/spritesheet@2x.png', 'checked@2x', );
$contact-location-2x-name: 'contact-location@2x';
$contact-location-2x-x: 64px;
$contact-location-2x-y: 354px;
$contact-location-2x-offset-x: -64px;
$contact-location-2x-offset-y: -354px;
$contact-location-2x-width: 52px;
$contact-location-2x-height: 68px;
$contact-location-2x-total-width: 502px;
$contact-location-2x-total-height: 486px;
$contact-location-2x-image: '../images/spritesheet@2x.png';
$contact-location-2x: (64px, 354px, -64px, -354px, 52px, 68px, 502px, 486px, '../images/spritesheet@2x.png', 'contact-location@2x', );
$download-small-2x-name: 'download-small@2x';
$download-small-2x-x: 474px;
$download-small-2x-y: 288px;
$download-small-2x-offset-x: -474px;
$download-small-2x-offset-y: -288px;
$download-small-2x-width: 18px;
$download-small-2x-height: 36px;
$download-small-2x-total-width: 502px;
$download-small-2x-total-height: 486px;
$download-small-2x-image: '../images/spritesheet@2x.png';
$download-small-2x: (474px, 288px, -474px, -288px, 18px, 36px, 502px, 486px, '../images/spritesheet@2x.png', 'download-small@2x', );
$envelope-share-2x-name: 'envelope-share@2x';
$envelope-share-2x-x: 166px;
$envelope-share-2x-y: 354px;
$envelope-share-2x-offset-x: -166px;
$envelope-share-2x-offset-y: -354px;
$envelope-share-2x-width: 50px;
$envelope-share-2x-height: 50px;
$envelope-share-2x-total-width: 502px;
$envelope-share-2x-total-height: 486px;
$envelope-share-2x-image: '../images/spritesheet@2x.png';
$envelope-share-2x: (166px, 354px, -166px, -354px, 50px, 50px, 502px, 486px, '../images/spritesheet@2x.png', 'envelope-share@2x', );
$explore-small-2x-name: 'explore-small@2x';
$explore-small-2x-x: 474px;
$explore-small-2x-y: 254px;
$explore-small-2x-offset-x: -474px;
$explore-small-2x-offset-y: -254px;
$explore-small-2x-width: 26px;
$explore-small-2x-height: 34px;
$explore-small-2x-total-width: 502px;
$explore-small-2x-total-height: 486px;
$explore-small-2x-image: '../images/spritesheet@2x.png';
$explore-small-2x: (474px, 254px, -474px, -254px, 26px, 34px, 502px, 486px, '../images/spritesheet@2x.png', 'explore-small@2x', );
$facebook-share-2x-name: 'facebook-share@2x';
$facebook-share-2x-x: 266px;
$facebook-share-2x-y: 354px;
$facebook-share-2x-offset-x: -266px;
$facebook-share-2x-offset-y: -354px;
$facebook-share-2x-width: 50px;
$facebook-share-2x-height: 50px;
$facebook-share-2x-total-width: 502px;
$facebook-share-2x-total-height: 486px;
$facebook-share-2x-image: '../images/spritesheet@2x.png';
$facebook-share-2x: (266px, 354px, -266px, -354px, 50px, 50px, 502px, 486px, '../images/spritesheet@2x.png', 'facebook-share@2x', );
$facebook-white-2x-name: 'facebook-white@2x';
$facebook-white-2x-x: 82px;
$facebook-white-2x-y: 432px;
$facebook-white-2x-offset-x: -82px;
$facebook-white-2x-offset-y: -432px;
$facebook-white-2x-width: 40px;
$facebook-white-2x-height: 40px;
$facebook-white-2x-total-width: 502px;
$facebook-white-2x-total-height: 486px;
$facebook-white-2x-image: '../images/spritesheet@2x.png';
$facebook-white-2x: (82px, 432px, -82px, -432px, 40px, 40px, 502px, 486px, '../images/spritesheet@2x.png', 'facebook-white@2x', );
$facebook-2x-name: 'facebook@2x';
$facebook-2x-x: 316px;
$facebook-2x-y: 354px;
$facebook-2x-offset-x: -316px;
$facebook-2x-offset-y: -354px;
$facebook-2x-width: 40px;
$facebook-2x-height: 40px;
$facebook-2x-total-width: 502px;
$facebook-2x-total-height: 486px;
$facebook-2x-image: '../images/spritesheet@2x.png';
$facebook-2x: (316px, 354px, -316px, -354px, 40px, 40px, 502px, 486px, '../images/spritesheet@2x.png', 'facebook@2x', );
$filter-dropdown-2x-name: 'filter-dropdown@2x';
$filter-dropdown-2x-x: 122px;
$filter-dropdown-2x-y: 432px;
$filter-dropdown-2x-offset-x: -122px;
$filter-dropdown-2x-offset-y: -432px;
$filter-dropdown-2x-width: 40px;
$filter-dropdown-2x-height: 24px;
$filter-dropdown-2x-total-width: 502px;
$filter-dropdown-2x-total-height: 486px;
$filter-dropdown-2x-image: '../images/spritesheet@2x.png';
$filter-dropdown-2x: (122px, 432px, -122px, -432px, 40px, 24px, 502px, 486px, '../images/spritesheet@2x.png', 'filter-dropdown@2x', );
$flex-left-2x-name: 'flex-left@2x';
$flex-left-2x-x: 282px;
$flex-left-2x-y: 200px;
$flex-left-2x-offset-x: -282px;
$flex-left-2x-offset-y: -200px;
$flex-left-2x-width: 92px;
$flex-left-2x-height: 92px;
$flex-left-2x-total-width: 502px;
$flex-left-2x-total-height: 486px;
$flex-left-2x-image: '../images/spritesheet@2x.png';
$flex-left-2x: (282px, 200px, -282px, -200px, 92px, 92px, 502px, 486px, '../images/spritesheet@2x.png', 'flex-left@2x', );
$flex-right-2x-name: 'flex-right@2x';
$flex-right-2x-x: 410px;
$flex-right-2x-y: 0px;
$flex-right-2x-offset-x: -410px;
$flex-right-2x-offset-y: 0px;
$flex-right-2x-width: 92px;
$flex-right-2x-height: 92px;
$flex-right-2x-total-width: 502px;
$flex-right-2x-total-height: 486px;
$flex-right-2x-image: '../images/spritesheet@2x.png';
$flex-right-2x: (410px, 0px, -410px, 0px, 92px, 92px, 502px, 486px, '../images/spritesheet@2x.png', 'flex-right@2x', );
$footer-logo-2x-name: 'footer-logo@2x';
$footer-logo-2x-x: 0px;
$footer-logo-2x-y: 0px;
$footer-logo-2x-offset-x: 0px;
$footer-logo-2x-offset-y: 0px;
$footer-logo-2x-width: 410px;
$footer-logo-2x-height: 200px;
$footer-logo-2x-total-width: 502px;
$footer-logo-2x-total-height: 486px;
$footer-logo-2x-image: '../images/spritesheet@2x.png';
$footer-logo-2x: (0px, 0px, 0px, 0px, 410px, 200px, 502px, 486px, '../images/spritesheet@2x.png', 'footer-logo@2x', );
$linkedin-share-2x-name: 'linkedin-share@2x';
$linkedin-share-2x-x: 116px;
$linkedin-share-2x-y: 354px;
$linkedin-share-2x-offset-x: -116px;
$linkedin-share-2x-offset-y: -354px;
$linkedin-share-2x-width: 50px;
$linkedin-share-2x-height: 50px;
$linkedin-share-2x-total-width: 502px;
$linkedin-share-2x-total-height: 486px;
$linkedin-share-2x-image: '../images/spritesheet@2x.png';
$linkedin-share-2x: (116px, 354px, -116px, -354px, 50px, 50px, 502px, 486px, '../images/spritesheet@2x.png', 'linkedin-share@2x', );
$linkedin-white-2x-name: 'linkedin-white@2x';
$linkedin-white-2x-x: 356px;
$linkedin-white-2x-y: 354px;
$linkedin-white-2x-offset-x: -356px;
$linkedin-white-2x-offset-y: -354px;
$linkedin-white-2x-width: 40px;
$linkedin-white-2x-height: 40px;
$linkedin-white-2x-total-width: 502px;
$linkedin-white-2x-total-height: 486px;
$linkedin-white-2x-image: '../images/spritesheet@2x.png';
$linkedin-white-2x: (356px, 354px, -356px, -354px, 40px, 40px, 502px, 486px, '../images/spritesheet@2x.png', 'linkedin-white@2x', );
$linkedin-2x-name: 'linkedin@2x';
$linkedin-2x-x: 396px;
$linkedin-2x-y: 354px;
$linkedin-2x-offset-x: -396px;
$linkedin-2x-offset-y: -354px;
$linkedin-2x-width: 40px;
$linkedin-2x-height: 40px;
$linkedin-2x-total-width: 502px;
$linkedin-2x-total-height: 486px;
$linkedin-2x-image: '../images/spritesheet@2x.png';
$linkedin-2x: (396px, 354px, -396px, -354px, 40px, 40px, 502px, 486px, '../images/spritesheet@2x.png', 'linkedin@2x', );
$location-2x-name: 'location@2x';
$location-2x-x: 0px;
$location-2x-y: 432px;
$location-2x-offset-x: 0px;
$location-2x-offset-y: -432px;
$location-2x-width: 42px;
$location-2x-height: 54px;
$location-2x-total-width: 502px;
$location-2x-total-height: 486px;
$location-2x-image: '../images/spritesheet@2x.png';
$location-2x: (0px, 432px, 0px, -432px, 42px, 54px, 502px, 486px, '../images/spritesheet@2x.png', 'location@2x', );
$mail-list-dropdown-2x-name: 'mail-list-dropdown@2x';
$mail-list-dropdown-2x-x: 410px;
$mail-list-dropdown-2x-y: 332px;
$mail-list-dropdown-2x-offset-x: -410px;
$mail-list-dropdown-2x-offset-y: -332px;
$mail-list-dropdown-2x-width: 36px;
$mail-list-dropdown-2x-height: 22px;
$mail-list-dropdown-2x-total-width: 502px;
$mail-list-dropdown-2x-total-height: 486px;
$mail-list-dropdown-2x-image: '../images/spritesheet@2x.png';
$mail-list-dropdown-2x: (410px, 332px, -410px, -332px, 36px, 22px, 502px, 486px, '../images/spritesheet@2x.png', 'mail-list-dropdown@2x', );
$phone-2x-name: 'phone@2x';
$phone-2x-x: 272px;
$phone-2x-y: 296px;
$phone-2x-offset-x: -272px;
$phone-2x-offset-y: -296px;
$phone-2x-width: 52px;
$phone-2x-height: 52px;
$phone-2x-total-width: 502px;
$phone-2x-total-height: 486px;
$phone-2x-image: '../images/spritesheet@2x.png';
$phone-2x: (272px, 296px, -272px, -296px, 52px, 52px, 502px, 486px, '../images/spritesheet@2x.png', 'phone@2x', );
$popover-arrow-2x-name: 'popover-arrow@2x';
$popover-arrow-2x-x: 410px;
$popover-arrow-2x-y: 92px;
$popover-arrow-2x-offset-x: -410px;
$popover-arrow-2x-offset-y: -92px;
$popover-arrow-2x-width: 78px;
$popover-arrow-2x-height: 82px;
$popover-arrow-2x-total-width: 502px;
$popover-arrow-2x-total-height: 486px;
$popover-arrow-2x-image: '../images/spritesheet@2x.png';
$popover-arrow-2x: (410px, 92px, -410px, -92px, 78px, 82px, 502px, 486px, '../images/spritesheet@2x.png', 'popover-arrow@2x', );
$property-arrow-2x-name: 'property-arrow@2x';
$property-arrow-2x-x: 410px;
$property-arrow-2x-y: 174px;
$property-arrow-2x-offset-x: -410px;
$property-arrow-2x-offset-y: -174px;
$property-arrow-2x-width: 76px;
$property-arrow-2x-height: 80px;
$property-arrow-2x-total-width: 502px;
$property-arrow-2x-total-height: 486px;
$property-arrow-2x-image: '../images/spritesheet@2x.png';
$property-arrow-2x: (410px, 174px, -410px, -174px, 76px, 80px, 502px, 486px, '../images/spritesheet@2x.png', 'property-arrow@2x', );
$slick-next-2x-name: 'slick-next@2x';
$slick-next-2x-x: 0px;
$slick-next-2x-y: 354px;
$slick-next-2x-offset-x: 0px;
$slick-next-2x-offset-y: -354px;
$slick-next-2x-width: 64px;
$slick-next-2x-height: 78px;
$slick-next-2x-total-width: 502px;
$slick-next-2x-total-height: 486px;
$slick-next-2x-image: '../images/spritesheet@2x.png';
$slick-next-2x: (0px, 354px, 0px, -354px, 64px, 78px, 502px, 486px, '../images/spritesheet@2x.png', 'slick-next@2x', );
$slick-prev-2x-name: 'slick-prev@2x';
$slick-prev-2x-x: 410px;
$slick-prev-2x-y: 254px;
$slick-prev-2x-offset-x: -410px;
$slick-prev-2x-offset-y: -254px;
$slick-prev-2x-width: 64px;
$slick-prev-2x-height: 78px;
$slick-prev-2x-total-width: 502px;
$slick-prev-2x-total-height: 486px;
$slick-prev-2x-image: '../images/spritesheet@2x.png';
$slick-prev-2x: (410px, 254px, -410px, -254px, 64px, 78px, 502px, 486px, '../images/spritesheet@2x.png', 'slick-prev@2x', );
$twitter-share-2x-name: 'twitter-share@2x';
$twitter-share-2x-x: 216px;
$twitter-share-2x-y: 354px;
$twitter-share-2x-offset-x: -216px;
$twitter-share-2x-offset-y: -354px;
$twitter-share-2x-width: 50px;
$twitter-share-2x-height: 50px;
$twitter-share-2x-total-width: 502px;
$twitter-share-2x-total-height: 486px;
$twitter-share-2x-image: '../images/spritesheet@2x.png';
$twitter-share-2x: (216px, 354px, -216px, -354px, 50px, 50px, 502px, 486px, '../images/spritesheet@2x.png', 'twitter-share@2x', );
$twitter-slide-2x-name: 'twitter-slide@2x';
$twitter-slide-2x-x: 164px;
$twitter-slide-2x-y: 200px;
$twitter-slide-2x-offset-x: -164px;
$twitter-slide-2x-offset-y: -200px;
$twitter-slide-2x-width: 118px;
$twitter-slide-2x-height: 96px;
$twitter-slide-2x-total-width: 502px;
$twitter-slide-2x-total-height: 486px;
$twitter-slide-2x-image: '../images/spritesheet@2x.png';
$twitter-slide-2x: (164px, 200px, -164px, -200px, 118px, 96px, 502px, 486px, '../images/spritesheet@2x.png', 'twitter-slide@2x', );
$twitter-white-2x-name: 'twitter-white@2x';
$twitter-white-2x-x: 436px;
$twitter-white-2x-y: 354px;
$twitter-white-2x-offset-x: -436px;
$twitter-white-2x-offset-y: -354px;
$twitter-white-2x-width: 40px;
$twitter-white-2x-height: 40px;
$twitter-white-2x-total-width: 502px;
$twitter-white-2x-total-height: 486px;
$twitter-white-2x-image: '../images/spritesheet@2x.png';
$twitter-white-2x: (436px, 354px, -436px, -354px, 40px, 40px, 502px, 486px, '../images/spritesheet@2x.png', 'twitter-white@2x', );
$twitter-2x-name: 'twitter@2x';
$twitter-2x-x: 42px;
$twitter-2x-y: 432px;
$twitter-2x-offset-x: -42px;
$twitter-2x-offset-y: -432px;
$twitter-2x-width: 40px;
$twitter-2x-height: 40px;
$twitter-2x-total-width: 502px;
$twitter-2x-total-height: 486px;
$twitter-2x-image: '../images/spritesheet@2x.png';
$twitter-2x: (42px, 432px, -42px, -432px, 40px, 40px, 502px, 486px, '../images/spritesheet@2x.png', 'twitter@2x', );
$white-dropdown-2x-name: 'white-dropdown@2x';
$white-dropdown-2x-x: 324px;
$white-dropdown-2x-y: 296px;
$white-dropdown-2x-offset-x: -324px;
$white-dropdown-2x-offset-y: -296px;
$white-dropdown-2x-width: 66px;
$white-dropdown-2x-height: 38px;
$white-dropdown-2x-total-width: 502px;
$white-dropdown-2x-total-height: 486px;
$white-dropdown-2x-image: '../images/spritesheet@2x.png';
$white-dropdown-2x: (324px, 296px, -324px, -296px, 66px, 38px, 502px, 486px, '../images/spritesheet@2x.png', 'white-dropdown@2x', );
$spritesheet-width: 251px;
$spritesheet-height: 243px;
$spritesheet-image: '../images/spritesheet.png';
$spritesheet-sprites: ($arrow-left, $arrow-right, $brochure, $bullet, $checkbox, $checked, $contact-location, $download-small, $envelope-share, $explore-small, $facebook-share, $facebook-white, $facebook, $filter-dropdown, $flex-left, $flex-right, $footer-logo, $linkedin-share, $linkedin-white, $linkedin, $location, $mail-list-dropdown, $phone, $popover-arrow, $property-arrow, $slick-next, $slick-prev, $twitter-share, $twitter-slide, $twitter-white, $twitter, $white-dropdown, );
$spritesheet: (251px, 243px, '../images/spritesheet.png', $spritesheet-sprites, );
$retina-spritesheet-width: 502px;
$retina-spritesheet-height: 486px;
$retina-spritesheet-image: '../images/spritesheet@2x.png';
$retina-spritesheet-sprites: ($arrow-left-2x, $arrow-right-2x, $brochure-2x, $bullet-2x, $checkbox-2x, $checked-2x, $contact-location-2x, $download-small-2x, $envelope-share-2x, $explore-small-2x, $facebook-share-2x, $facebook-white-2x, $facebook-2x, $filter-dropdown-2x, $flex-left-2x, $flex-right-2x, $footer-logo-2x, $linkedin-share-2x, $linkedin-white-2x, $linkedin-2x, $location-2x, $mail-list-dropdown-2x, $phone-2x, $popover-arrow-2x, $property-arrow-2x, $slick-next-2x, $slick-prev-2x, $twitter-share-2x, $twitter-slide-2x, $twitter-white-2x, $twitter-2x, $white-dropdown-2x, );
$retina-spritesheet: (502px, 486px, '../images/spritesheet@2x.png', $retina-spritesheet-sprites, );

/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
$arrow-left-group-name: 'arrow-left';
$arrow-left-group: ('arrow-left', $arrow-left, $arrow-left-2x, );
$arrow-right-group-name: 'arrow-right';
$arrow-right-group: ('arrow-right', $arrow-right, $arrow-right-2x, );
$brochure-group-name: 'brochure';
$brochure-group: ('brochure', $brochure, $brochure-2x, );
$bullet-group-name: 'bullet';
$bullet-group: ('bullet', $bullet, $bullet-2x, );
$checkbox-group-name: 'checkbox';
$checkbox-group: ('checkbox', $checkbox, $checkbox-2x, );
$checked-group-name: 'checked';
$checked-group: ('checked', $checked, $checked-2x, );
$contact-location-group-name: 'contact-location';
$contact-location-group: ('contact-location', $contact-location, $contact-location-2x, );
$download-small-group-name: 'download-small';
$download-small-group: ('download-small', $download-small, $download-small-2x, );
$envelope-share-group-name: 'envelope-share';
$envelope-share-group: ('envelope-share', $envelope-share, $envelope-share-2x, );
$explore-small-group-name: 'explore-small';
$explore-small-group: ('explore-small', $explore-small, $explore-small-2x, );
$facebook-share-group-name: 'facebook-share';
$facebook-share-group: ('facebook-share', $facebook-share, $facebook-share-2x, );
$facebook-white-group-name: 'facebook-white';
$facebook-white-group: ('facebook-white', $facebook-white, $facebook-white-2x, );
$facebook-group-name: 'facebook';
$facebook-group: ('facebook', $facebook, $facebook-2x, );
$filter-dropdown-group-name: 'filter-dropdown';
$filter-dropdown-group: ('filter-dropdown', $filter-dropdown, $filter-dropdown-2x, );
$flex-left-group-name: 'flex-left';
$flex-left-group: ('flex-left', $flex-left, $flex-left-2x, );
$flex-right-group-name: 'flex-right';
$flex-right-group: ('flex-right', $flex-right, $flex-right-2x, );
$footer-logo-group-name: 'footer-logo';
$footer-logo-group: ('footer-logo', $footer-logo, $footer-logo-2x, );
$linkedin-share-group-name: 'linkedin-share';
$linkedin-share-group: ('linkedin-share', $linkedin-share, $linkedin-share-2x, );
$linkedin-white-group-name: 'linkedin-white';
$linkedin-white-group: ('linkedin-white', $linkedin-white, $linkedin-white-2x, );
$linkedin-group-name: 'linkedin';
$linkedin-group: ('linkedin', $linkedin, $linkedin-2x, );
$location-group-name: 'location';
$location-group: ('location', $location, $location-2x, );
$mail-list-dropdown-group-name: 'mail-list-dropdown';
$mail-list-dropdown-group: ('mail-list-dropdown', $mail-list-dropdown, $mail-list-dropdown-2x, );
$phone-group-name: 'phone';
$phone-group: ('phone', $phone, $phone-2x, );
$popover-arrow-group-name: 'popover-arrow';
$popover-arrow-group: ('popover-arrow', $popover-arrow, $popover-arrow-2x, );
$property-arrow-group-name: 'property-arrow';
$property-arrow-group: ('property-arrow', $property-arrow, $property-arrow-2x, );
$slick-next-group-name: 'slick-next';
$slick-next-group: ('slick-next', $slick-next, $slick-next-2x, );
$slick-prev-group-name: 'slick-prev';
$slick-prev-group: ('slick-prev', $slick-prev, $slick-prev-2x, );
$twitter-share-group-name: 'twitter-share';
$twitter-share-group: ('twitter-share', $twitter-share, $twitter-share-2x, );
$twitter-slide-group-name: 'twitter-slide';
$twitter-slide-group: ('twitter-slide', $twitter-slide, $twitter-slide-2x, );
$twitter-white-group-name: 'twitter-white';
$twitter-white-group: ('twitter-white', $twitter-white, $twitter-white-2x, );
$twitter-group-name: 'twitter';
$twitter-group: ('twitter', $twitter, $twitter-2x, );
$white-dropdown-group-name: 'white-dropdown';
$white-dropdown-group: ('white-dropdown', $white-dropdown, $white-dropdown-2x, );
$retina-groups: ($arrow-left-group, $arrow-right-group, $brochure-group, $bullet-group, $checkbox-group, $checked-group, $contact-location-group, $download-small-group, $envelope-share-group, $explore-small-group, $facebook-share-group, $facebook-white-group, $facebook-group, $filter-dropdown-group, $flex-left-group, $flex-right-group, $footer-logo-group, $linkedin-share-group, $linkedin-white-group, $linkedin-group, $location-group, $mail-list-dropdown-group, $phone-group, $popover-arrow-group, $property-arrow-group, $slick-next-group, $slick-prev-group, $twitter-share-group, $twitter-slide-group, $twitter-white-group, $twitter-group, $white-dropdown-group, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
