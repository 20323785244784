.single-portfolio {
    header {
        @media (min-width: $screen-md-min) {
            height: 400px;
        }
    }
}

.portfolio-single-title {
    position: relative;
    
    h3 {
        font-size: 25px;
        
        span {
            font-weight: 500;
        }
    }
    
    .social-share {
        border: 1px solid #35845a;
        position: absolute;
        right: 70px;
        top: 0;
        padding: 48px 15px;
        border-radius: 50%;
        
        .share {
            font-size: 16px;
            letter-spacing: 2px;
        }
        
        li {
            width: 20px;
            padding: 0;
            margin: 0 5px;
            
            & > a {
                text-indent: -9999px;
                display: block;
                padding: 0;
                
                &:hover {
                    background: transparent;
                }
            }
    
            &.envelope a {
                @include retina-sprite($envelope-share-group);
            }
    
            &.facebook a {
                @include retina-sprite($facebook-share-group);
            }
    
            &.twitter a {
                @include retina-sprite($twitter-share-group);
            }
    
            &.linkedin a {
                @include retina-sprite($linkedin-share-group);
            }
        }
    }
}

.property-meta {

    @media (min-width: $screen-md-min) {
        height: 105px;
    }
    
    #property-footage {
        font-family: 'proxima-nova';
        font-weight: 500;
        font-size: 22px;
        padding: 38px 0 15px;
        
        @media (min-width: $screen-md-min) {
            padding: 22px 0 16px;
        }
    }
    
    #property-resources {
        padding: 9px 0 38px;
        
        @media (min-width: $screen-md-min) {
            padding: 9px 0;
        }
        
        .dl-brochure {
            font-size: 20px;
            position: relative;
            margin: 10px 0;
            padding-left: 35px;
            
            a:hover {
                color: #fff;
            }
            
            a:before {
                @include retina-sprite($brochure-group);
                content: " ";
                position: absolute;
                left: 0;
                top: -4px;
            }
        }
        
        .explore {
            font-size: 20px;
            position: relative;
            margin: 10px 0;
            padding-left: 35px;
            
            a:hover {
                color: #fff;
            }
            
            a:before {
                @include retina-sprite($location-group);
                content: " ";
                position: absolute;
                left: 0;
                top: 0px;
            }
        }
    }
}

#property-sf {
    color: #34f18d;
}

#flexslider-portfolio {
    margin: 0 !important;
    border: 0 none;
    background: transparent;
    
    .flex-direction-nav a::before {
        content: " ";
        display: inline-block;
        font-family: flexslider-icon;
        font-size: 40px;
        @include retina-sprite($flex-left-group);
    }
    .flex-direction-nav a.flex-next::before {
        content: " ";
        @include retina-sprite($flex-right-group);
    }
    .flex-direction-nav a {
        height: 46px;
        width: 46px;
        margin: -23px 0 0;
    }
    .flex-direction-nav .flex-prev {
        left: 25px;
        opacity: 1;
        
        @media (max-width: $screen-sm-min - 1) {
            display: none;
        }
    }
    .flex-direction-nav .flex-next {
        right: 25px;
        opacity: 1;
        
        @media (max-width: $screen-sm-min - 1) {
            display: none;
        }
    }
}

aside {
    .property-manager,
    .exclusive-agent,
    .for-more-info {
        margin-bottom: 25px;
        
        .contact-detail {
            color: #7d7d7d;
            font-weight: 300;
            font-size: 19px;
            line-height: 1.3;
            
            a {
                color: #2bb676;
                font-weight: 500;
            }
        }
    }
}

#gmap {
    height: 400px;
    overflow: hidden;
}

